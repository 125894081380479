import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default function useEmployeeList() {
  // Use toast
  const toast = useToast()

  const refEmployeeListTable = ref(null)

  const perPage = ref(5)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)

  const employeeData = JSON.parse(localStorage.getItem('employeeData'))
  const branchId = router.currentRoute.query.branch_id ?? employeeData.branch_id

  const branchFilter = ref(parseInt(branchId, 10))
  const saleRegionFilter = ref(null)

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'admin') return 'primary'
    if (role === 'sale') return 'success'
    if (role === 'store') return 'info'
    if (role === 'manager') return 'secondary'
    if (role === 'audit') return 'warning'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'admin') return 'ShieldIcon'
    if (role === 'sale') return 'ShoppingBagIcon'
    if (role === 'store') return 'ArchiveIcon'
    if (role === 'manager') return 'BriefcaseIcon'
    if (role === 'audit') return 'UserCheckIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 1) return 'success'
    if (status === 0) return 'danger'
    return 'primary'
  }

  const dataMeta = computed(() => {
    const localItemsCount = refEmployeeListTable.value ? refEmployeeListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refEmployeeListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, statusFilter, branchFilter, saleRegionFilter], () => {
    refetchData()
  })

  const fetchItems = (ctx, callback) => {
    store
      .dispatch('/fetchAll', {
        q: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value,
        sort_by: sortBy.value,
        sort_desc: isSortDirDesc.value,
        role_id: roleFilter.value,
        status: statusFilter.value,
        branch_id: branchFilter.value,
        sale_region_id: saleRegionFilter.value,
      })
      .then(response => {
        const { employees, total } = response.data.data

        callback(employees)
        totalItems.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error getting employee list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchItems,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refEmployeeListTable,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    statusFilter,
    branchFilter,
    saleRegionFilter,
  }
}
